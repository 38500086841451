<template>
  <div class="animated fadeIn">
    <div class="table-container">
      <div class="list-wrapper">
        <div>
          <div class="j_datagrid__header type01">
            <div class="header__title">
              <h5>
                All
                <small>Group Code</small>
              </h5>
            </div>

            <div class="header__right">
              <v-flex>
                <button class="jcon_add" @click="setAdd()"></button>
              </v-flex>
            </div>
          </div>

          <j-data-grid
            v-model="selected"
            item-key="sn"
            hide-actions
            draggable
            :headers="headers"
            :items="codeGroup"
            @filtered="onFiltered"
            @row-move-to="onRowMoved"
            @sorted="onSorted"
          >
            <template v-slot:items="props">
              <tr :active="props.selected" @click="propStatus(props)">
                <td style="text-align: center;" class="handle">
                  <button class="jcon_order"></button>
                </td>
                <td style="text-align: center;">{{ props.item.no }}</td>
                <td>{{ props.item.code }}</td>
                <td>{{ props.item.name }}</td>
                <td>{{ props.item.desc }}</td>
              </tr>
            </template>
          </j-data-grid>
        </div>
      </div>

      <j-form-modal
        modal-type="type00"
        title="Group Code"
        ref="formHandler"
        @create="add()"
        @edit="edit()"
        @delete="del()"
        @cancel="cancel()"
        :formMode="formode"
        :resetable="resetable()"
        :opened.sync="modOpen"
      >
        <v-container>
          <v-layout wrap>
            <v-flex id="inputGroupCode" xs12 :class="{ disabled: formode === 'MOD' }">
              <v-text-field
                v-model="selectedItem.code"
                required
                placeholder="Edit after the Name input"
                label="Group Code"
                :disabled="selectedItem.name == ''"
                :rules="codeRules"
              ></v-text-field>
            </v-flex>
            <v-flex xs12>
              <v-text-field
                v-model="selectedItem.name"
                required
                label="Group Title"
                placeholder="Input/Edit group Title"
                @input="eventInput"
                :rules="nameRules"
              ></v-text-field>
            </v-flex>
            <v-flex xs12>
              <v-text-field
                v-model="selectedItem.desc"
                required
                label="Group Description"
                placeholder="Input/Edit group description"
              ></v-text-field>
            </v-flex>
          </v-layout>
        </v-container>
      </j-form-modal>
    </div>

    <j-alert
      v-model="msgOpen"
      :type="msgInfo.type"
      :title="msgInfo.title"
      :titleDescription="msgInfo.titleDescription"
      :message="msgInfo.message"
      :button="msgInfo.button"
      :buttonText="msgInfo.buttonText"
      @yes="yes()"
      @cancel="msgOpen = false"
    ></j-alert>

    <j-snackbar
      :snackbar="snackbar"
      :snackbarColor="'success'"
      :closeBtnColor="'buttonColor'"
      :positionX="'top'"
      :positionY="'right'"
      :top="'85px'"
      :timeout="2000"
      :mode="'mode'"
      :text="'Priority changed'"
    ></j-snackbar>
  </div>
</template>

<script>
import __C from '@/primitives/_constant_'
import { SystemService } from "@/services"
import JFormModal from "@/components/floating/JFormModal"
import JSnackbar from "@/components/JSnackbar"

export default {
  name: "sysenv_code",
  components: {
    JFormModal,
    JSnackbar
  },
  data: () => ({
    systemService: null,

    jOpen: false,
    jFormOpen: false,

    modOpen: false,
    snackbar: false,
    rowSorted: false,
    rowDropped: false,
    selected: [],
    selectedIndex: -2,
    selectedItem: {},
    selectedPrevItem: [],
    headers: [
      { type: "drag", text: "", align: "right", width: 30 },
      { type: "conf", text: "No", align: "center", width: 40 },
      { type: "text", text: "Group Code", align: "left", width: 150 },
      { type: "text", text: "Group Title", align: "left", width: 170 },
      { type: "text", text: "Group Description", align: "left" }
    ],
    codeGroup: [],
    loading: false,
    select: null,
    formode: __C.FORM.EDIT_MODE_NEW,
    valid: false,
    codeRules: [
      v => !!v || "Required",
      v => (!!v && /^[A-Z0-9_]*$/.test(v)) || 'Uppercase and "_" only',
      v => (!!v && v.length >= 2) || "At least 2 characters",
      v => (!!v && v.length < 50) || "Less then 50 characters"
    ],
    nameRules: [
      v => !!v || "Required",
      v => (!!v && v.length >= 1) || "At least 1 characters",
      v => (!!v && v.length < 50) || "Less then 50 characters"
    ],
    msgOpen: false,
    msgInfo: {
      type: "",
      title: "",
      titleDescription: "",
      message: "",
      button: [true, false, true],
      buttonText: ["Yes", "No", "Cancel"]
    },
    yes: () => { }
  }),
  watch: {
    "selectedItem.name"(val) {
      if (this.selectedIndex > -1) return;
      if (!val) {
        this.selectedItem.code = "";
        return;
      }
      let code = val
        .toUpperCase()
        .replace(/[^A-Z0-9_\s]/g, "")
        .replace(/[\s]/g, "_")
        .replace(/(_)\1+/g, "_");
      this.selectedItem.code = code;
    },
    rowDropped(val) {
      if (val) {
        this.rowDropped = false;

        // TODO: Save changed priorities of rows
      }
    }
  },
  created() {
    this.systemService = new SystemService();
    this.systemService.getCodeGroup(this.setCodeGroup);
    this.codeRules.push(v => this.isCodeValid(v) || "Duplicated");
  },
  computed: {
    codePropagated: {
      get() {
        return this.$store.state.sysenv.codePropagated;
      },
      set(value) {
        this.$store.commit("sysenv/codePropagate", value);
      }
    }
  },
  methods: {
    eventInput() {
      if (this.selectedItem.name != "" && !!this.selectedItem.name)
        document
          .getElementById("inputGroupCode")
          .classList.toggle("disabled", false);
      else
        document
          .getElementById("inputGroupCode")
          .classList.toggle("disabled", true);
    },
    setCodeGroup(res) {
      this.modOpen = false;

      if (!res) res = [];

      // 'no' field is generated by v-data-table's items="props" action
      // because of that props.item.no is defined into the data-table's
      // item template, so it doesn't need to be add manually... like...
      // res.forEach(item => { item['no'] = 0 })
      this.codeGroup = res;

      // this action comes from very first time accessed or page reloaded...
      if (this.selectedIndex == -2) {
        this.selectedIndex = -1;
        // if this action comes from that add new group code,
        // right added item should be selected manually.
      } else if (this.selectedIndex == -1) {
        this.setEdit(
          this.codeGroup.findIndex(x => x.code === this.selectedItem.code)
        );
      } else {
        // if this action comes from that delete existing group code,
        // select a forward or backward item.
        if (
          this.codeGroup.findIndex(x => x.code === this.selectedItem.code) < 0
        ) {
          if (this.codeGroup.length > 0) {
            if (this.codeGroup.length >= this.selectedIndex + 1) {
              // Nothing to do...
            } else {
              this.selectedIndex = this.codeGroup.length - 1;
            }
            this.setEdit(this.selectedIndex);
          } else {
            // this.setAdd()
          }
        } else {
          this.setEdit(this.selectedIndex);
        }
      }
    },
    onRowMoved(val) {
      const rowSelected = this.codeGroup.splice(val.oldIndex, 1)[0]; // Get the selected row and remove it
      this.codeGroup.splice(val.newIndex, 0, rowSelected); // Move it to the new index
      this.rowDropped = true;
      this.snackbar = false;
      this.systemService.setPriority(
        {
          newIdex: val.newIndex,
          oldIndex: val.oldIndex,
          targetName: "CODE_GROUP"
        },
        () => {
          this.snackbar = true;
        }
      );
    },
    onFiltered(items) {
      // Renumbering for the action of Drag & Drop and search filtering.
      let reNumbering = 0;
      items.forEach(_item => {
        this.codeGroup.find(
          _item_ => _item_.code == _item.code
        ).no = ++reNumbering;
      });
    },
    onSorted(items) {
      items.forEach((item, index) => {
        item.no = index + 1;
      });
    },
    add() {
      this.yes = () => {
        this.msgOpen = false;
        this.systemService.putCodeGroup(this.selectedItem, res => {
          this.systemService.getCodeGroup(this.setCodeGroup);
        });
        this.yes = () => { };
      };
      this.msgInfo.type = "INFO";
      this.msgInfo.title = "Save Changes.";
      this.msgInfo.titleDescription = "";
      this.msgInfo.message = "Do you want to save new Group Code?";
      this.msgInfo.buttonText[0] = "Save";
      this.msgOpen = true;
    },
    edit() {
      this.yes = () => {
        this.msgOpen = false;
        this.systemService.updCodeGroup(this.selectedItem, res => {
          this.systemService.getCodeGroup(this.setCodeGroup);
        });
        this.yes = () => { };
      };
      this.msgInfo.type = "INFO";
      this.msgInfo.title = "Save Changes.";
      this.msgInfo.titleDescription = "";
      this.msgInfo.message = "Do you want to save this changes?";
      this.msgInfo.buttonText[0] = "Save";
      this.msgOpen = true;
    },
    del() {
      this.yes = () => {
        this.msgOpen = false;
        this.systemService.delCodeGroup(this.selectedItem.code, res => {
          this.systemService.getCodeGroup(this.setCodeGroup);
        });
        this.yes = () => { };
      };
      this.msgInfo.type = "WARN";
      this.msgInfo.title = "Action Approval";
      this.msgInfo.titleDescription = "Important Notification";
      // this.msgInfo.message = 'Do you want to delete selected Code Group?'
      this.msgInfo.message = `
        <span style="
          font-style: italic; 
          font-weight: 300; 
          font-size: 12px; 
          color: #f75c02
        ">
          Attention: All of its code index will be deleted.
        </span><br /><br />
        Do you want to delete selected Code Group?
      `;
      this.msgInfo.buttonText[0] = "Delete";
      this.msgOpen = true;
    },
    querySelections(v) {
      this.loading = true;
      // Simulated ajax query
      setTimeout(() => {
        this.items = this.codeGroup.filter(e => {
          return (
            (e.name || "").toLowerCase().indexOf((v || "").toLowerCase()) > -1
          );
        });
        this.loading = false;
      }, 500);
    },
    changeSort(column) {
      if (this.pagination.sortBy === column) {
        this.pagination.descending = !this.pagination.descending;
      } else {
        this.pagination.sortBy = column;
        this.pagination.descending = false;
      }
    },
    propStatus(props) {
      this.selectedIndex = this.codeGroup.indexOf(props.item);
      this.selectedItem = Object.assign({}, props.item);

      if (this.selectedPrevItem.length > 0) {
        if (this.selectedPrevItem[0].code === props.item.code) {
          this.modOpen = true;
          return;
        } else {
          let _selected = props.item;
          this.selected = [_selected];
        }
      } else {
        props.selected = !props.selected;
      }
      this.selectedPrevItem = [...this.selected];
      this.formode = __C.FORM.EDIT_MODE_MOD
      this.modOpen = true;
    },
    setAdd() {
      this.selectedIndex = -1;
      this.selectedItem = {};
      this.selected = [];
      this.selectedPrevItem = [];
      this.formode = __C.FORM.EDIT_MODE_NEW
      this.$refs.formHandler.formReset();
      this.modOpen = true;
    },
    setEdit(selectedIndex) {
      // myArray.map(x => x.hello).indexOf('stevie')
      this.selectedIndex = selectedIndex;
      this.selectedItem = Object.assign({}, this.codeGroup[this.selectedIndex]);
      this.selected = [Object.assign({}, this.codeGroup[this.selectedIndex])];
      this.selectedPrevItem = [...this.selected];
      this.formode = __C.FORM.EDIT_MODE_MOD
    },
    resetable() {
      if (this.selectedIndex < 0) {
        if (!!this.selectedItem.name) return true;
        return false;
      } else {
        if (
          this.selectedPrevItem[0].name != this.selectedItem.name ||
          this.selectedPrevItem[0].desc != this.selectedItem.desc
        )
          return true;
        return false;
      }
    },
    reset() {
      if (this.selectedIndex < 0) {
        this.selectedItem = {};
        this.$refs.formHandler.formReset();
      } else {
        this.selectedItem = Object.assign({}, this.selectedPrevItem[0]);
      }
    },
    cancel() {
      this.modOpen = false;
    },
    isCodeValid(code) {
      if (this.formode == __C.FORM.EDIT_MODE_NEW) {
        return !this.codeGroup.find(item => item.code == code);
      } else {
        return true;
      }
    }
  }
};
</script>
